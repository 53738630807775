import React, { useEffect, useState } from "react";
import Webinar from "../webinar/Webinar";
import RecentVideo from "./RecentVideo";
import Helmet from "react-helmet";
import WatchNow from "./WatchNow";
import TemplateCount from "./TemplateCount";
import Plyr from "plyr";
import DashboardPopup from "./DashboardPopup";
import { connect } from "react-redux";
import { openCloseModal, closeShowPopup } from "../../actions/auth";
import { setLoader } from "../../actions/loader";

let playerPlyr = false;

export function Dashboard({
  modalStatus,
  openCloseModal,
  user,
  loading,
  closeShowPopup,
  loader,
  setLoader,
}) {
  const [close, setClose] = useState(false);
  const [user_id, setUserId] = useState("");
  const [accountType, setAccountType] = useState([]);
  const [showPopupOne, setshowPopupOne] = useState(false);

  useEffect(() => {
    setAccountType(loading || !user ? [] : [...user.account_type]);
  }, [user, loading]);

  useEffect(() => {
    if (user) {
      setUserId(loading || !user.user_id ? "" : user.user_id);
    }
  }, [user, loading]);

  useEffect(() => {
    setClose(modalStatus);
  }, [modalStatus]);

  const destroyPlayer = () => {
    if (playerPlyr.length > 0) {
      playerPlyr[0].pause();
      // for (const playerEl of player) {
      //     playerEl.pause();
      // }
    }
  };
  useEffect(() => {
    playerPlyr = Plyr.setup("#player");
    return () => {
      if (playerPlyr.length > 0) {
        playerPlyr[0].pause();
        // for (const playerEl of player) {
        //     playerEl.pause();
        // }
      }
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>VideoReel | Dasboard</title>
      </Helmet>
      <section id="intro-section">
        <div id="bg-video">
          <iframe
            src="https://player.vimeo.com/video/511005726?background=1&autoplay=1&loop=1&byline=0&title=0"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            title="course"
          ></iframe>
        </div>
        <div className="container">
          <div className="row">
            <WatchNow />
            <TemplateCount />
          </div>
        </div>
      </section>
      <RecentVideo />
      <Webinar />
      {/* Watch Video Modal */}

      {close && accountType.length === 1 && accountType.includes("com") ? (
        <DashboardPopup
          setClose={openCloseModal}
          showPopupOne={showPopupOne}
          setshowPopupOne={setshowPopupOne}
          closeShowPopup={closeShowPopup}
          user_id={user_id}
          loader={loader}
          setLoader={setLoader}
        />
      ) : null}

      <div
        id="watch-video"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered mx-auto"
          role="document"
        >
          <div className="modal-content mx-auto">
            <button
              onClick={destroyPlayer}
              type=" button"
              className="close mb-3 text-right"
              data-dismiss="modal"
              aria-label="Close"
              style={{ opacity: 1 }}
            >
              <img
                className="img-fluid"
                src="./assets/images/close.svg"
                alt="Close"
              />
            </button>
            <div
              className="modal-body border-0 p-0"
              style={{ boxShadow: "none" }}
            >
              <div className="vid-wrapper">
                <div
                  className="plyr__video-embed"
                  id="player"
                // data-plyr-provider="vimeo"
                // data-plyr-embed-id="512159766"
                >
                  <iframe
                    src="https://player.vimeo.com/video/512159766?background=1&loop=1&byline=0&title=0"
                    frameborder="0"
                    allow="autoplay"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Watch Video Modal */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  modalStatus: state.auth.showPopup,
  user: state.auth.user,
  loading: state.auth.loading,
  loader: state.loader.loader,
});

export default connect(mapStateToProps, {
  openCloseModal,
  closeShowPopup,
  setLoader,
})(Dashboard);
