import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { SketchPicker } from "react-color";
import { useEffect } from "react";
import EditPopup from "./EditPopup";
import { connect } from "react-redux";
import { baseURL } from "../../../global/global";
import { setAlert } from "../../../actions/alert";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Alert from "../../alert/Alert";
import { setLoader, removeLoader } from "../../../actions/loader";
import { save_seek_value } from "../../../actions/seekValue";
import { frontURL } from "../../../global/global";
// import { hexToRgb } from "../../../global/hexToRgb";

const EditPart = ({
  templateData,
  auth: { user, loading },
  setAlert,
  project_id,
  template_id,
  loader,
  setLoader,
  removeLoader,
  save_seek_value,
  languageTranslate,
}) => {
  // const [background, setBackground] = useState("#fff");
  const [showColorPicker, setShowColorPicker] = useState({
    open: false,
    colorName: "",
  });
  const [editData, setEditData] = useState({});
  const [tabName, setTabName] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [projectName, setProjectName] = useState("");
  const [user_id, setUserId] = useState("");
  const [projectNameExist, setProjectNameExist] = useState(true);
  const [audioObject, setAudioObject] = useState({});
  const [audioEvent, setAudioEvent] = useState({});
  const [saveRenderCheck, setSaveRenderCheck] = useState("");
  // const [imagesForVideo, setImagesForVideo] = useState([]);
  // const [text, setText] = useState([]);
  const [language, setLanguage] = useState([]);
  const [loaderForTranslate, setLoaderForTranslate] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState("-Select-");
  const [allDataForTextTranslation, setAllDataForTextTranslation] = useState(
    []
  );

  const wrapperRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    setEditData(
      templateData
        ? {
          ...templateData,
          images: templateData.images.map((img) => {
            return { ...img, thumbnail: img.thumbnail ? img.thumbnail : "" };
          }),
          texts: templateData.texts.map((value) => {
            return {
              ...value,
              text: value.text.substring(0, value.maxLimit),
            };
          }),
        }
        : {}
    );
    setAllDataForTextTranslation(
      templateData.texts.map((text) => ({
        text: text.text,
        layerName: text.layerName,
      }))
    );
  }, [templateData]);

  // set audio url
  useEffect(() => {
    setAudioUrl((aud) =>
      aud !== "" ? aud : editData.audio_url ? editData.audio_url : ""
    );
  }, [editData]);

  useEffect(() => {
    setUserId(loading || !user.user_id ? "" : user.user_id);
  }, [user, loading]);

  //  Set Project name
  useEffect(() => {
    setProjectName(
      editData.project_name
        ? editData.project_name
        : "campagin_" + Math.round(new Date().getTime() / 1000)
    );
  }, [editData.project_name]);

  useEffect(() => {
    setLogoUrl(editData.logo_url ? editData.logo_url : "");
  }, [editData.logo_url]);

  // useEffect(() => {
  //   console.log("Raj");
  //   setImagesForVideo(
  //     editData.images
  //       ? editData.images.map((value) => {
  //           return { ...value, videoImgUrl: "" };
  //         })
  //       : []
  //   );
  // }, [editData]);

  // Handle click outside ColorPicker Hide
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowColorPicker({ open: false, colorName: "" });
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  // Color Chanage
  const handleChangeComplete = (colorChange) => {
    // setBackground(colorChange.hex);
    setEditData((data) => ({
      ...data,
      texts: data.texts.map((data) =>
        data.layerName === showColorPicker.colorName
          ? { ...data, color: colorChange.hex }
          : data
      ),
    }));
  };

  // Saving Data
  const saveToServer = () => {
    const data = {
      type: "save_project",
      user_id: user_id,
      project_name: projectName,
      template_id: editData.template_id,
      texts_data: editData.texts
        ? JSON.stringify(editData.texts.map((text) => text))
        : [],
      images_data: editData.images
        ? JSON.stringify(editData.images.map((image) => image))
        : [],
      audio_url: audioUrl,
      logo_url: logoUrl,
      project_id: project_id ? project_id : "",
      texts_color_data: editData.texts_color,
    };
    setSaveRenderCheck("save");
    setLoader();
    saveAndRenderToServer(data);
  };

  const renderToServer = () => {
    const data = {
      type: "render_project",
      user_id: user_id,
      project_name: projectName,
      template_id: editData.template_id,
      texts_data: editData.texts
        ? JSON.stringify(editData.texts.map((text) => text))
        : [],
      images_data: editData.images
        ? JSON.stringify(editData.images.map((image) => image))
        : [],
      audio_url: audioUrl,
      logo_url: logoUrl,
      project_id: project_id ? project_id : "",
      texts_color_data: editData.texts_color,
    };
    setSaveRenderCheck("render");
    setLoader();
    saveAndRenderToServer(data);
  };

  // Save and Render
  const saveAndRenderToServer = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);

    try {
      const res = await axios.post(`${baseURL}save-project`, body, config);

      if (res.data.status === true) {
        setAlert(res.data.message, "success");
        removeLoader();
        return history.push(`/my-videos`);
      } else if (res.data.status === false) {
        removeLoader();
      }
    } catch (err) {
      removeLoader();
      console.log(err.response);
    }
  };

  const openModalFunc = (data) => {
    setTabName(data);
    setModalOpen(true);
  };

  const setImagesData = (imgName, src, thumb) => {
    setEditData((value) => ({
      ...value,
      images: value.images.map((value) =>
        value.layerName === imgName
          ? { ...value, path: src, thumbnail: thumb }
          : value
      ),
    }));
  };

  const setTextData = (e, textName, maxLimit) => {
    let val = "";

    if (typeof e === "string") {
      val = e;
    } else {
      val = maxLimit > 0 ? e.target.value.slice(0, maxLimit) : e.target.value;
    }
    // setText((value) =>
    //   value.map((value) =>
    //     value.layerName === textName ? { ...value, text: val } : value
    //   )
    // );

    setEditData((value) => ({
      ...value,
      texts: value.texts.map((value) =>
        value.layerName === textName ? { ...value, text: val } : value
      ),
    }));
  };

  // setting logo url
  const updateLogoUrl = (url) => {
    setLogoUrl(url);
  };

  // setting logo url
  const updateAudioUrl = (url) => {
    setAudioUrl(url);
  };

  // checking project name

  useEffect(() => {
    const searchProjectName = async (data) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify(data);

      try {
        const res = await axios.post(
          `${baseURL}check-project-name`,
          body,
          config
        );

        if (res.data.status === true) {
          setAlert(res.data.message, "danger");
          setProjectNameExist(true);
        } else {
          setProjectNameExist(false);
        }
      } catch (err) {
        console.log(err.response);
      }
    };

    if (project_id || template_id) {
      searchProjectName({
        project_name: projectName,
        type: project_id ? "edit" : "create",
        id: project_id ? project_id : null,
      });
    }
  }, [project_id, template_id, projectName, setAlert]);

  useEffect(() => {
    if (audioUrl) {
      setAudioObject(new Audio(audioUrl));
      // audioEvent.src = "./assets/images/play-button_3.svg";
    }
  }, [audioUrl]);

  useEffect(() => {
    return () => {
      if (
        !(
          Object.keys(audioObject).length === 0 &&
          audioObject.constructor === Object
        )
      ) {
        audioObject.pause();
      }
    };
  }, [audioObject]);

  useEffect(() => {
    if (
      !(
        Object.keys(audioObject).length === 0 &&
        audioObject.constructor === Object
      )
    ) {
      audioObject.onended = () => {
        audioEvent.src = "./assets/images/play-button_3.svg";
      };
    }
  }, [audioEvent, audioObject]);

  const popover = {
    position: "fixed",
    zIndex: "1000",
    left: "270px",
    top: "240px",
  };
  const cover = {
    position: "relative",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  const swatch = {
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    cursor: "pointer",
    width: "50px",
    height: "30px",
    borderRadius: "5px",
  };

  // language translation
  useEffect(() => {
    if (languageTranslate.length > 0) {
      setLanguage([...languageTranslate]);
    }
  }, [languageTranslate]);

  const changeText = async () => {
    if (selectLanguage === "-Select-") return;

    let data = {
      lang_code: selectLanguage,
      text: [...allDataForTextTranslation],
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify(data);
    setLoaderForTranslate(true);

    try {
      const res = await axios.post(`${baseURL}api/translate`, body, config);

      if (res.data.status === true) {
        setEditData((value) => ({
          ...value,
          texts: value.texts.map((value, index) => ({
            ...value,
            ...res.data.data[index],
          })),
        }));
        setLoaderForTranslate(false);
      } else if (res.data.status === false) {
        setLoaderForTranslate(false);
      }
    } catch (err) {
      // removeLoader();
      setLoaderForTranslate(false);
      console.log(err.response);
    }
  };

  // const keyPress = (e, textName) => {
  //   // if (e.keyCode == 13) {
  //   //   // e.preventDefault();
  //   //   setEditData((value) => ({
  //   //     ...value,
  //   //     texts: value.texts.map((value) =>
  //   //       value.layerName === textName
  //   //         ? { ...value, text: value.text + "\\n" }
  //   //         : value
  //   //     ),
  //   //   }));
  //   //   // put the login here
  //   // }
  // };

  return (
    <EditingStyle>
      <Alert />

      <div className="edit_part">
        <div
          className="row w-100 justify-content-center"
          style={{ positon: "absolute", left: "206%", top: "2%" }}
        >
          <div className="col-md-6 text-white">
            {loader ? (
              <button
                className="btn btn-2 accnt-mngmt-btn mr-xl-2 mb-2"
                type="button"
                disabled
              >
                {saveRenderCheck === "save" ? (
                  <>
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <i className="fa fa-floppy-o" aria-hidden="true"></i>{" "}
                    Saving...
                  </>
                ) : (
                  <>
                    <i className="fa fa-floppy-o" aria-hidden="true"></i> Save
                  </>
                )}
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-2 accnt-mngmt-btn mr-xl-2 mb-2"
                onClick={saveToServer}
                disabled={!projectNameExist && projectName ? false : true}
              >
                <i className="fa fa-floppy-o" aria-hidden="true"></i> Save
              </button>
            )}
          </div>
          <div className="col-md-6 text-white pr-0">
            {loader ? (
              <button
                className="btn btn-3 accnt-mngmt-btn mb-2"
                type="button"
                disabled
              >
                {" "}
                {saveRenderCheck === "render" ? (
                  <>
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <i className="fa fa-crosshairs" aria-hidden="true"></i>{" "}
                    rendering...
                  </>
                ) : (
                  <>
                    <i className="fa fa-crosshairs" aria-hidden="true"></i>{" "}
                    Render
                  </>
                )}
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-3 accnt-mngmt-btn mb-2"
                onClick={renderToServer}
                disabled={!projectNameExist && projectName ? false : true}
              >
                <i className="fa fa-crosshairs" aria-hidden="true"></i> Render
              </button>
            )}
          </div>
        </div>
        <div style={{ position: "absolute", top: "0px", width: "100%" }}>
          <ul
            className="nav nav-tabs d-flex edit_nav border border-0"
            role="tablist"
          >
            <li className="nav-item flex-fill text-center ">
              <a
                className="nav-link active edit_tab"
                data-toggle="tab"
                href="#general"
              >
                <i className="fa fa-cog" aria-hidden="true"></i> General
              </a>
            </li>
            <li className="nav-item flex-fill text-center ">
              <a className="nav-link edit_tab" data-toggle="tab" href="#images">
                <i className="fa fa-camera" aria-hidden="true"></i> Media
              </a>
            </li>
            <li className="nav-item flex-fill text-center">
              <a className="nav-link edit_tab" data-toggle="tab" href="#text">
                <i className="fa fa-text-width" aria-hidden="true"></i> Text
              </a>
            </li>
          </ul>

          {/* <!-- Tab panes --> */}
          <div className="tab-content" style={{ overflowY: "scroll" }}>
            <div id="general" className="container tab-pane active">
              <br />
              <div className="row general_row">
                <div className="col-md-5 text-right gen-name">
                  Project Name :{" "}
                </div>
                <div className="col-md-7">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group form-group-mb m-0">
                      {/* <label className="input-label">Name </label> */}
                      <input
                        className="form-control input-dark-accnt personal-info w-100"
                        type="text"
                        name="project"
                        placeholder="Enter Project"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        required
                        style={{ height: "40px" }}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="row general_row">
                <div className="col-md-5 text-right gen-name">Logo : </div>
                <div className="col-md-7 d-flex justify-content-between">
                  <div>
                    <img
                      src={
                        logoUrl !== "" ? logoUrl : "./assets/images/logoNot.png"
                      }
                      alt=""
                      width="30px"
                      height="30px"
                      onClick={(e) => {
                        if (logoUrl) {
                          setLogoUrl("");
                        } else {
                          openModalFunc({
                            first: "Logo Library",
                            second: "Your logo",
                            third: "Upload Logo",
                          });
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <button
                    type="button"
                    className="edit-button"
                    onClick={() =>
                      openModalFunc({
                        first: "Logo Library",
                        second: "Your logo",
                        third: "Upload Logo",
                      })
                    }
                  >
                    <i className="fa fa-pencil-square-o" aria-hidden="true"></i>{" "}
                    Edit
                  </button>
                </div>
              </div>
              <div className="row general_row">
                <div className="col-md-5 text-right gen-name">Audio : </div>
                <div className="col-md-7 d-flex justify-content-between">
                  <div>
                    <img
                      src={
                        audioUrl === ""
                          ? "./assets/images/musicNot.png"
                          : "./assets/images/play-button_3.svg"
                      }
                      alt=""
                      style={{
                        cursor: "pointer",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                      }}
                      onClick={(e) => {
                        if (
                          Object.keys(audioObject).length === 0 &&
                          audioObject.constructor === Object
                        ) {
                          setAudioEvent(e.target);
                        }
                        if (
                          e.target.src === `${frontURL}/assets/images/pause.svg`
                        ) {
                          e.target.src = "./assets/images/play-button_3.svg";
                          setAudioEvent(e.target);
                          audioObject.pause();
                          return;
                        } else if (
                          e.target.src ===
                          `${frontURL}/assets/images/play-button_3.svg`
                        ) {
                          e.target.src = "./assets/images/pause.svg";
                          if (
                            !(
                              Object.keys(audioObject).length === 0 &&
                              audioObject.constructor === Object
                            )
                          ) {
                            audioObject.play();
                            setAudioEvent(e.target);
                          }
                          return;
                        }
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    className="edit-button"
                    onClick={() => {
                      if (
                        !(
                          Object.keys(audioObject).length === 0 &&
                          audioObject.constructor === Object
                        )
                      ) {
                        audioObject.pause();
                        audioEvent.src = "./assets/images/play-button_3.svg";
                      }
                      openModalFunc({
                        first: "Audio Library",
                        second: "Your Audio",
                        third: "Text To Speech",
                      });
                    }}
                  >
                    <i className="fa fa-pencil-square-o" aria-hidden="true"></i>{" "}
                    Edit
                  </button>
                </div>
              </div>
            </div>
            <div id="images" className="container tab-pane fade">
              <br />
              {!editData.images ? (
                <></>
              ) : (
                editData.images.map((image, index) => (
                  <div
                    className="row general_row align-items-center"
                    key={index}
                  >
                    <div
                      className="col-3 d-flex justify-content-start align-items-start"
                      style={{ height: "60px" }}
                    >
                      <p>{image.layerName}</p>
                    </div>
                    <div className="col-5 d-flex justify-content-end">
                      <div>
                        <img
                          src={
                            image.thumbnail.length > 0
                              ? image.thumbnail
                              : image.path
                          }
                          alt=""
                          style={{
                            objectFit: "cover",
                            border: "2px solid white",
                            cursor: "pointer",
                            width: "7vw",
                            height: "8vh",
                          }}
                          onClick={() => save_seek_value(image.startTime)}
                        />
                      </div>
                    </div>
                    <div
                      className="col-4 d-flex justify-content-end align-items-start"
                      style={{ height: "60px" }}
                    >
                      <button
                        type="button"
                        className="edit-button"
                        onClick={() => {
                          save_seek_value(image.startTime);
                          openModalFunc({
                            first: "Images Library",
                            second: "Your Images",
                            third: "Upload Images",
                            imgName: image.layerName,
                          });
                        }}
                      >
                        <i
                          className="fa fa-pencil-square-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Edit
                      </button>
                    </div>
                  </div>
                ))
              )}
            </div>
            <div id="text" className="container tab-pane fade">
              <br />
              <div className="row general_row justify-content-center align-items-center">
                <div className="col-7 ml-0 pl-0">
                  <div className="form-group form-group-mb">
                    <label className="input-label">
                      Select a language to translate
                    </label>
                    <select
                      className="form-control input-account-type p-accnt"
                      id="Account_selector"
                      name="voice"
                      value={selectLanguage}
                      onChange={(e) => {
                        setSelectLanguage(e.target.value);
                      }}
                    >
                      <option value="-Select-" id="yt_video">
                        -Select-
                      </option>
                      {language.length > 0
                        ? language.map((lang) => {
                          return (
                            <option value={lang.code} key={lang.id}>
                              {lang.name}
                            </option>
                          );
                        })
                        : null}
                    </select>
                  </div>
                </div>

                <div className="col-4 text-white pr-0">
                  <div className="row justify-content-start">
                    <div className="col-8">
                      {loaderForTranslate ? (
                        <button
                          className="btn btn-3 accnt-mngmt-btn mb-2"
                          type="button"
                          disabled
                        >
                          {" "}
                          <>
                            <span
                              className="spinner-grow spinner-grow-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-3 accnt-mngmt-btn mb-2"
                          onClick={changeText}
                          disabled={
                            selectLanguage !== "-Select-" ? false : true
                          }
                        >
                          Go
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {!editData.texts ? (
                <></>
              ) : (
                editData.texts.map((text, index) => (
                  <div className="row general_row" key={index}>
                    <div className="col-8">
                      <div className="row justify-content-between">
                        <p className="pl-3">{text.layerName}</p>
                        <p style={{ paddingRight: "30px" }}>
                          {text.text.length}/{text.maxLimit}
                        </p>
                      </div>
                      <div>
                        <form>
                          <div className="form-row">
                            <div className="col-11">
                              <textarea
                                className="form-control"
                                value={text.text}
                                maxLength={text.maxLimit}
                                onFocus={() => save_seek_value(text.startTime)}
                                onChange={(e) => {
                                  if (text.text.length >= text.maxLimit)
                                    return setTextData(
                                      e,
                                      text.layerName,
                                      text.maxLimit
                                    );
                                  return setTextData(e, text.layerName, "");
                                }}
                              // onPaste={(e) => {
                              //   e.preventDefault();
                              //   setTextData(
                              //     e.clipboardData
                              //       .getData("Text")
                              //       .slice(0, text.maxLimit),
                              //     text.layerName,
                              //     ""
                              //   );
                              // }}
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="row d-flex justify-content-start align-items-center">
                        <div
                          className="col-12 mx-0 p-0"
                          style={{
                            font: "400 14px/1.19 'Montserrat', Sans-serif",
                            color: "#9e9e9e",
                          }}
                        >
                          Color
                        </div>
                        <div
                          className="col-6 mx-0 p-0 mt-2"
                          style={swatch}
                          onClick={() => {
                            save_seek_value(text.startTime);
                            setShowColorPicker((value) => ({
                              ...value,
                              open: true,
                              colorName: text.layerName,
                            }));
                          }}
                        >
                          <div
                            style={{
                              background: `${text.color}`,
                              height: "100%",
                              borderRadius: "5px",
                              border: "1px solid black",
                            }}
                          />
                          {/* <div
                          className="col-8 text-dark"
                          style={{ fontSize: "12px" }}
                        >
                          {editData.texts_color[index].value}
                        </div> */}
                        </div>
                      </div>
                      {/* <button
                      type="button"
                      className="btn btn-primary"
                      // data-toggle="modal"
                      // data-target="#exampleModal"
                      onClick={() =>
                        setShowColorPicker((value) => ({
                          ...value,
                          open: true,
                          colorName: text.layerName,
                        }))
                      }
                      // style={{ background: `#${text.color}` }}
                    >
                      {text.color}
                    </button> */}
                      {showColorPicker.open &&
                        showColorPicker.colorName === text.layerName ? (
                        <div style={popover} ref={wrapperRef}>
                          <div
                            style={cover}
                            onClick={() => setShowColorPicker(false)}
                          />
                          <SketchPicker
                            color={text.color}
                            onChange={handleChangeComplete}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>

      {modalOpen ? (
        <EditPopup
          openCloseFunction={setModalOpen}
          tabName={tabName}
          changeImages={setImagesData}
          setLogoUrl={updateLogoUrl}
          setAudioUrl={updateAudioUrl}
          audioUrlSource={audioUrl}
        // setVidImgUrl={setImagesForVideo}
        />
      ) : null}
    </EditingStyle>
  );
};

const EditingStyle = styled.div`
  .edit_part {
    height: 69vh;
    position: relative;
  }

  .edit_tab {
    border-radius: 0;
    font: 400 16px/1.19 "Montserrat", Sans-serif;
    border: none;
    font-size: 0.9rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .edit_tab i {
    padding-bottom: 2px;
    padding-right: 2px;
  }

  .tab-content {
    height: 60vh;
  }

  .tab-pane {
    min-height: 60vh !important;
  }

  .edit_tab:hover {
    background-image: linear-gradient(
      150deg,
      #17d583 -11%,
      #00d2b3 39%,
      #5bc1de 59%,
      #1cb7f2 78%
    );
    color: #fff !important;

    i {
      color: #fff !important;
    }
  }

  li {
    width: 33.33%;
  }

  li .active {
    background-image: linear-gradient(
      150deg,
      #17d583 -11%,
      #00d2b3 39%,
      #5bc1de 59%,
      #1cb7f2 78%
    ) !important;
    color: #fff !important;
  }

  .general_row {
    margin-bottom: 2rem;
  }

  .gen-name {
    color: #9e9e9e;
    font-weight: 400;
  }

  .edit-button {
    background: none;
    color: #1cb7f2;
    border: none;
    padding: 0;
  }

  .edit-button:hover {
    color: #fff;
  }

  .edit-button:focus {
    background: transparent !important;
    border: 0 !important;
    outline-style: none !important;
  }
`;

EditPart.propTypes = {
  templateData: PropTypes.object,
  auth: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  loader: PropTypes.bool,
  removeLoader: PropTypes.func.isRequired,
  save_seek_value: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
});

export default connect(mapStateToProps, {
  setAlert,
  setLoader,
  removeLoader,
  save_seek_value,
})(EditPart);
