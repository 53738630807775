import React, { useEffect, useState } from "react";
import SaveHeader from "../CreateVideos/nomEditor/SaveHeader/SaveHeader";
import Media from "./Media";
import Navigation from "./Navigation";
import ReelMergeVideo from "./ReelMergeVideo";
import TimeLine from "./TimeLine";
import "../CreateVideos/nomEditor/Editor/style.css";
import queryString from "query-string";
import { connect } from "react-redux";
import { loadMergeData, clearTemplate } from "../../actions/video";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

const ReelMerge = (props) => {
  const [selectVideo, setSelectVideo] = useState([]);
  const [user_id, setUserId] = useState("");
  const [prevwImg, setPrevwImg] = useState([]);
  const [accountType, setAccountType] = useState([]);

  const project_ids = queryString.parse(props.location.search).project_id;

  useEffect(() => {
    setAccountType(
      props.auth.loading || !props.auth.user
        ? []
        : [...props.auth.user.account_type]
    );
  }, [props.auth.user, props.auth.loading]);

  useEffect(() => {
    setUserId(
      props.auth.loading || !props.auth.user.user_id
        ? ""
        : props.auth.user.user_id
    );
  }, [props.auth.user, props.auth.loading]);

  useEffect(() => {
    if (project_ids) {
      props.loadMergeData({ id: project_ids, user_id: user_id });
    }
    return () => {
      props.clearTemplate();
    };
  }, [props.loadMergeData, props.clearTemplate, project_ids, user_id]);

  useEffect(() => {
    if (props.loadingTemplate.mergeData.length > 0) {
      console.log("real");
      setSelectVideo(JSON.parse(props.loadingTemplate.mergeData[0].data));
    }
  }, [props.loadingTemplate.mergeData, props.loadingTemplate.loading]);

  useEffect(() => {
    setPrevwImg(selectVideo.map((val) => val.project_id));
  }, [selectVideo]);

  if (accountType.length > 0) {
    if (
      !(
        accountType.includes("unlimited") ||
        accountType.includes("lite") ||
        accountType.includes("bundle")
      )
    ) {
      return <Redirect to="/dashboard" />;
    }
  }


  return (
    <div className="editor bg-dark">
      <Helmet>
        <title>VideoReel | ReelMerge</title>
      </Helmet>
      <div id="editor-wrapper" className="wrapper">
        <SaveHeader />
        <div
          id="editor-nav"
          className="nav flex-column nav-pills text-center nav-sidebar-bg"
          role="tablist"
          aria-orientation="vertical"
        ></div>
        <div id="editor-tab-wrap" className="tab-content">
          <div
            id="editor-tab-cont"
            className="tab-pane fade show active"
            role="tabpanel"
            aria-labelledby="editor-tab"
          >
            <div className="nav-wrapper w-100">
              <Navigation selectVideo={selectVideo} id={project_ids} />
              <div className="preview-wrapper">
                <div className="controls-wrapper">
                  <ul className="controls d-flex flex-wrap justify-content-center align-items-center">
                    <li className="control-btn pr-4">
                      <a
                        id="undo"
                        href="!#"
                        className="text-white"
                        data-toggle="tooltip"
                        title="Undo"
                      ></a>
                    </li>
                  </ul>
                  <div className="object-prev">
                    <div className="canvas">
                      <ReelMergeVideo selectVideo={selectVideo} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="inner-tab-cont" className="tab-content">
              <div id="media" className={"tab-pane fade show active"}>
                <Media setSelectVideo={setSelectVideo} prevwImg={prevwImg} />
              </div>
            </div>

            <TimeLine
              selectVideo={selectVideo}
              setSelectVideo={setSelectVideo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  loader: state.loader.loader,
  loadingTemplate: state.loadingTemplate,
});

export default connect(mapStateToProps, { loadMergeData, clearTemplate })(
  ReelMerge
);
