import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import "./App.css";
import Dashboard from "./components/Dashboard/Dashboard";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Profile from "./components/Profile/Profile";
import Notifications from "./components/Notifications/Notifications";
import Account from "./components/Account/Account";
import Integration from "./components/Integration/Integration";
import Login from "./components/auth/Login";
import Forgot from "./components/auth/Forgot";
import ResetPassword from "./components/auth/ResetPassword";
import PrivateRoute from "./components/routing/PrivateRoute";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import { loadUser } from "./actions/auth";
import Privacy from "./components/privacy/Privacy.jsx";
import PrivacyPolicy from "./components/Footer/PrivacyPolicy";
import Editor from "./components/CreateVideos/editor/Editor";
import EditorNom from "./components/CreateVideos/nomEditor/Editor/Editor";
import MyVideo from "./components/CreateVideos/myVideo/MyVideo";
import Template from "./components/CreateVideos/templates/Template";
import Support from "./components/support/Support";
import SupportDetail from "./components/support/SupportDetail";
import SupportArticle from "./components/support/SupportArticle";
import Extra from "./components/extra/Extra";
import LoginLoader from "./components/loginLoader/LoginLoader";
import FbAssets from "./components/contractAssets/FbAssets";
import ClientContract from "./components/contractAssets/ClientContract";
import AgencyWebsite from "./components/contractAssets/AgencyWebsite";
import BusinessFinder from "./businessFinder/BusinessFinder";
import WlRebranding from "./components/rebranding/WlRebranding";
import DfyCourse from "./components/dfycourse/DfyCourse";
import ResellerLicense from "./components/resellerLicense/ResellerLicense";
import WebHosting from "./components/webhosting/WebHosting";
import DfyArticles from "./components/dfyArticles/DfyArticles";
import VideoPromos from "./components/videoPromos/VideoPromos";
import VideoFlix from "./components/videoFlix/VideoFlix";
import SocialMedia from "./components/socialMedia/SocialMedia";
import VideoCommercial from "./components/videoCommercial/VideoCommercial";
import UdemyStyle from "./components/udemyStyle/UdemyStyle";
import ReelMerge from "./components/reelMerge/ReelMerge";
import MyMergeVideo from "./components/myMergeVideos/MyMergeVideo";
import Upgrades from "./components/upgrade/Upgrades";
import MasterLogin from "./components/masterLogin/MasterLogin";
import WhiteLabel from "./components/whiteLabel/WhiteLabel";
import Temp from "./components/whiteLabel/Temp";
import Funnel from "./components/whiteLabel/Funnel";
import Payment from "./components/whiteLabel/Payment";
import SMTP from "./components/whiteLabel/SMTP";
import PreviewDetails from "./components/whiteLabel/PreviewDetails";
import ViewDetails from "./components/whiteLabel/ViewDetails";
import WhiteLabelUser from "./components/whiteLabel/WhiteLabelUser";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  useEffect(() => {
    if (!window.location.href.includes("aksjry73984trkje")) {
      store.dispatch(loadUser());
    }
  }, []);

  return (
    <Provider store={store}>
      <Router>
        {window.location.href.lastIndexOf("/privacyPolicy") > -1 ||
          window.location.href.lastIndexOf("/aksjry73984trkje") > -1 ? null : (
          <Header />
        )}
        <Route exact path="/" component={Login} />

        <Switch>
          <Route exact path="/forget-password" component={Forgot} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/master-login" component={MasterLogin} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/notifications" component={Notifications} />
          <PrivateRoute exact path="/account" component={Account} />
          <PrivateRoute exact path="/integration" component={Integration} />
          <PrivateRoute exact path="/create-videos" component={Template} />
          <PrivateRoute exact path="/privacy" component={Privacy} />
          <PrivateRoute exact path="/editor" component={Editor} />
          <PrivateRoute exact path="/help-and-support" component={Support} />
          <PrivateRoute
            exact
            path="/support-detail"
            component={SupportDetail}
          />
          <PrivateRoute exact path="/fb-templates" component={FbAssets} />
          <PrivateRoute
            exact
            path="/client-contract"
            component={ClientContract}
          />
          <PrivateRoute
            exact
            path="/agency-website"
            component={AgencyWebsite}
          />
          <PrivateRoute exact path="/rebranding" component={WlRebranding} />
          <PrivateRoute
            exact
            path="/support-article"
            component={SupportArticle}
          />

          <PrivateRoute
            exact
            path="/reseller-license"
            component={ResellerLicense}
          />

          <PrivateRoute exact path="/web-hosting" component={WebHosting} />
          <PrivateRoute exact path="/dfy-articles" component={DfyArticles} />
          <PrivateRoute exact path="/video-promos" component={VideoPromos} />
          <PrivateRoute exact path="/video-flix" component={VideoFlix} />
          <PrivateRoute exact path="/reel-merge" component={ReelMerge} />
          <PrivateRoute exact path="/my-merge-video" component={MyMergeVideo} />
          <PrivateRoute exact path="/upgrades" component={Upgrades} />

          <PrivateRoute exact path="/dfy-course" component={DfyCourse} />

          <PrivateRoute
            exact
            path="/social-media-assets"
            component={SocialMedia}
          />

          <PrivateRoute
            exact
            path="/video-commercial"
            component={VideoCommercial}
          />

          <PrivateRoute exact path="/udemy-style" component={UdemyStyle} />

          <PrivateRoute
            exact
            path="/business-finder"
            component={BusinessFinder}
          />

          <PrivateRoute exact path="/whitelabel" component={WhiteLabel} />
          <PrivateRoute exact path="/temp" component={Temp} />
          <PrivateRoute exact path="/funnel" component={Funnel} />
          <PrivateRoute exact path="/payment" component={Payment} />
          <PrivateRoute exact path="/smtp" component={SMTP} />
          <PrivateRoute exact path="/preview-details" component={PreviewDetails} />
          <PrivateRoute exact path="/view-details" component={ViewDetails} />
          <PrivateRoute exact path="/whitelabel-users" component={WhiteLabelUser} />

          <PrivateRoute exact path="/my-videos" component={MyVideo} />
          {/* <PrivateRoute exact path="/my-videos" component={MyVideo} /> */}
          <PrivateRoute exact path="/preview-editor" component={EditorNom} />
          <Route exact path="/aksjry73984trkje" component={Extra} />
          <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
          <Redirect from="*" to="/" />
        </Switch>
        {window.location.href.lastIndexOf("/privacyPolicy") > -1 ||
          window.location.href.lastIndexOf("/aksjry73984trkje") > -1 ? null : (
          <Footer />
        )}

        <LoginLoader />
      </Router>
    </Provider>
  );
}

export default App;
