import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

function TemplateCount({ user, loading, dashboard }) {
  const [is_client, setIsClient] = useState(false);
  const [tempProjectNumber, setTempProjectNumber] = useState({});
  useEffect(() => {
    if (user) {
      setIsClient(loading || !user.is_client ? false : user.is_client);
    }
  }, [user, loading]);

  useEffect(() => {
    setTempProjectNumber(
      dashboard.loading || !dashboard.dashboard ? {} : dashboard.dashboard
    );
  }, [dashboard]);

  return (
    <>
      <div className="col colmn-2 col-lg-6 col-12">
        <div className="action-btn-wrapper">
          {is_client === true ? null : (
            <Link
              to="/create-videos"
              id="create-proj"
              className="action-btn sub-txt d-flex align-items-center ml-auto mr-lg-0 mr-auto mb-2"
            >
              <span className="icon text-white pr-3 fs-40">
                {tempProjectNumber.total_templates
                  ? tempProjectNumber.total_templates
                  : 0}
              </span>
              <span className="flex-grow-1 text-white pl-2 text-left">
                Templates Available
              </span>
              <span className="icon arrow" style={{ color: "#424344" }}>
                <svg width="28px" height="28px" viewBox="0 0 28 28">
                  <use xlinkHref="./assets/images/right-arrow.svg#rght-arrow" />
                </svg>
              </span>
            </Link>
          )}
          <Link
            to="/my-videos"
            className="action-btn sub-txt d-flex align-items-center ml-auto mr-lg-0 mr-auto mb-2"
          >
            <span className="icon text-white pr-3 fs-40">
              {tempProjectNumber.total_projects
                ? tempProjectNumber.total_projects
                : 0}
            </span>
            <span className="flex-grow-1 text-white pl-3 text-left">
              View All Videos
            </span>
            <span className="icon arrow" style={{ color: "#424344" }}>
              <svg width="28px" height="28px" viewBox="0 0 28 28">
                <use xlinkHref="./assets/images/right-arrow.svg#rght-arrow" />
              </svg>
            </span>
          </Link>
          {is_client === true ? null : (
            <Link
              to="/create-videos"
              className="action-btn sub-txt d-flex align-items-center ml-auto mr-lg-0 mr-auto"
              data-toggle
              data-target
            >
              <span className="icon text-white pr-3">
                <svg width="41px" height="34px" viewBox="0 0 41 34">
                  <use xlinkHref="./assets/images/ico-video.svg#ico-video" />
                </svg>
              </span>
              <span className="flex-grow-1 text-white pl-3 text-left">
                Create Videos
              </span>
              <span className="icon arrow" style={{ color: "#424344" }}>
                <svg width="28px" height="28px" viewBox="0 0 28 28">
                  <use xlinkHref="./assets/images/right-arrow.svg#rght-arrow" />
                </svg>
              </span>
            </Link>
          )}
        </div>
      </div>
    </>
  );
}

TemplateCount.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  dashboard: state.dashboardData,
});

export default connect(mapStateToProps)(TemplateCount);
